var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('vue-data-table',{attrs:{"cols":[
      {
        header: 'Order Reference',
        value: 'id',
      },
      {
        header: 'Customer',
        value: 'customer_name',
        sort: true
      },
      {
        header: 'Payment Status',
        value: 'billing_status',
        sort: true
      },
      {
        header: 'Fulfillment Status',
        value: 'fulfillment_status',
        sort: true
      },
      {
        header: 'Created At',
        value: 'created_at',
        sort: true
      },
      {
        header: 'Updated At',
        value: 'updated_at',
        sort: true
      },
      {
        header: 'Actions',
        value: 'actions'
      }
    ],"filter-keys":_vm.filterKeys,"path":"orders"},scopedSlots:_vm._u([{key:"item.billing_status",fn:function(row){return [_c('span',{staticClass:"rounded-full bg-primary text-white px-2 py-1"},[_vm._v(" "+_vm._s(row.billing_status_label)+" ")])]}},{key:"item.fulfillment_status",fn:function(row){return [_c('span',{staticClass:"rounded-full bg-primary text-white px-2 py-1"},[_vm._v(" "+_vm._s(row.fulfillment_status_label)+" ")])]}},{key:"item.created_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.created_at))+" ")]}},{key:"item.updated_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.updated_at))+" ")]}},{key:"item.actions",fn:function(row){return [_c('vue-button',{attrs:{"primary":"","sm":""},nativeOn:{"click":function($event){return _vm.openOrder(row.id)}}},[_vm._v(" View ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }